import PropTypes from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import style from './error-table.module.scss';

const ErrorTableComponent = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Card className={style.errorTable}>
      <Card.Body>
        <Table bordered hover responsive className="mb-0">
          <thead>
            <tr>
              <th>{t('error_table_component.table.header.line')}</th>
              <th>{t('error_table_component.table.header.error_message')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIdx) => (
              <tr key={rowIdx.toString()}>
                <td>{row.line}</td>
                <td>{row.message.map((msg, errorMessageIdx) => <p key={errorMessageIdx.toString()} className="mb-0">{msg}</p>)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

ErrorTableComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    line: PropTypes.number.isRequired,
    message: PropTypes.arrayOf(String).isRequired,
  })).isRequired,
};

export { ErrorTableComponent };
