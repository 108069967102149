import React from 'react';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Container className="mt-3">
      <Row>
        <Col lg="12">
          <Card className="shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-center align-items-center">
                <h1 className="me-3 pe-3 align-top border-end inline-block align-content-center">
                  404
                </h1>
                <div className="inline-block align-middle">
                  <h2 className="font-weight-normal lead">
                    {t('not_found_page.message')}
                  </h2>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { NotFoundPage };
