import { Auth, Hub } from 'aws-amplify';
import axios from 'axios';
import { encode } from 'base-64';
import {
  get, isEmpty, map, reduce,
} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  Alert, Card, Col, Container, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import io from 'socket.io-client';

import { ErrorTableComponent } from '../../components/ErrorTableComponent/ErrorTableComponent';
import { UploadFormComponent } from '../../components/UploadFormComponent/UploadFormComponent';
import { useAuth } from '../../services/auth_provider';

const {
  REACT_APP_HEADER_GRANT_READ, REACT_APP_CF_UPLOAD, REACT_APP_BACKEND_URL,
  REACT_APP_TLS,
} = process.env;
const BACKEND_URL_WSS = `ws${REACT_APP_TLS === 'true' ? 's' : ''}://${REACT_APP_BACKEND_URL}` || `wss://${window.location.host}`;
const BACKEND_URL_HTTPS = `http${REACT_APP_TLS === 'true' ? 's' : ''}://${REACT_APP_BACKEND_URL}` || `https://${window.location.host}`;
const FORMAT_INFORMATION_LINK = '/action_item_insert_template.xlsx';
const X_AMZ_GRANT_READ = 'x-amz-grant-read';

const WS_EVENTS = {
  PROCESS: 'process',
  AUTHORIZE: 'authorize',
};

const formatErrorMessage = (msg) => {
  let message = [msg.ErrorMessage];
  if (!isEmpty(get(msg, 'ActionItemProcessingError.Errors', {}))) {
    message = map(get(msg, 'ActionItemProcessingError.Errors'), (val) => val);
  }

  return {
    line: get(msg, 'ActionItemProcessingError.Line'),
    message,
  };
};

const UploadPage = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const ws = useRef(undefined);
  const [isProcessing, setProcessing] = useState(false);
  const [wsConnectionError, setWSConnectionError] = useState(false);
  const [invalidCSVURL, setInvalidCSVURL] = useState(undefined);
  const [serverError, setServerError] = useState(undefined);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleAuthorizationMessage = (msg) => {
    if (msg !== true) {
      setInvalidCSVURL(undefined);
      setProcessing(false);
      setErrorMessages([]);
      toast(t('upload_page.messages.authorization_failed'), { type: toast.TYPE.ERROR });
      auth.signOut();
    }
  };

  const handleProcessMessage = (msgs) => {
    const errors = reduce(msgs, (result, msg) => {
      if (!isEmpty(get(msg, 'InvalidCSVURL'))) {
        setInvalidCSVURL(get(msg, 'InvalidCSVURL'));
      } else if (get(msg, 'HasError', false) && msg.ActionItemProcessingError.Line !== 0) {
        result.push(formatErrorMessage(msg));
      } else if (get(msg, 'HasError', false) && msg.ActionItemProcessingError.Line === 0) {
        setServerError(msg.ErrorMessage);
      } else if (get(msg, 'Done', false)) {
        setProcessing(false);
      }
      return result;
    }, []);
    setErrorMessages((prev) => [...prev, ...errors]);
  };

  useEffect(() => {
    let isWSErrorDisplayed = false;
    ws.current = io.connect(BACKEND_URL_WSS, {
      path: '/ws',
      upgrade: false,
      transports: ['websocket'],
    });
    ws.current.on('connect_error', () => {
      if (!isWSErrorDisplayed) {
        setWSConnectionError(true);
      }
    });
    ws.current.on('connect', async () => {
      try {
        const currentSession = await Auth.currentSession();
        ws.current.emit(WS_EVENTS.AUTHORIZE, currentSession.getIdToken().getJwtToken());
      } catch (err) {
        auth.signOut();
      }
      isWSErrorDisplayed = false;
      setWSConnectionError(false);
    });
    ws.current.on(WS_EVENTS.AUTHORIZE, handleAuthorizationMessage);
    ws.current.on(WS_EVENTS.PROCESS, handleProcessMessage);

    Hub.listen('auth', async (data) => {
      try {
        const currentSession = await Auth.currentSession();

        if (data.payload.event === 'tokenRefresh' && ws.current.connected) {
          ws.current.emit(WS_EVENTS.AUTHORIZE, currentSession.getIdToken().getJwtToken());
        }
      } catch (err) {
        auth.signOut();
      }
    });
    return () => {
      if (ws.current !== undefined) {
        ws.current.close();
      }
    };
  }, []);

  const onSubmit = async ({ file }, resetForm) => {
    setInvalidCSVURL(undefined);
    setProcessing(true);
    setErrorMessages([]);

    const fileUrl = `${REACT_APP_CF_UPLOAD}/${file.name}`;
    const currentSession = await Auth.currentSession();
    const jwtToken = currentSession.getIdToken().getJwtToken();
    const encodedUriUrl = encodeURI(fileUrl);
    const encodedFileUrl = encode(encodedUriUrl);
    const signedUrlResponse = await axios.get(`${BACKEND_URL_HTTPS}/api/v1/sign-url?url=${encodedFileUrl}`, {
      headers: {
        Authorization: `BEARER ${jwtToken}`,
        [X_AMZ_GRANT_READ]: REACT_APP_HEADER_GRANT_READ,
      },
    });

    try {
      const signResponse = await axios.put(signedUrlResponse.data.url, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
          [X_AMZ_GRANT_READ]: REACT_APP_HEADER_GRANT_READ,
        },
      });

      if (signResponse.status !== 200) {
        toast(t('upload_page.messages.upload_failed'), { type: toast.TYPE.ERROR });

        return setProcessing(false);
      }

      toast(t('upload_page.messages.upload_successful'), { type: toast.TYPE.SUCCESS });

      ws.current.emit(WS_EVENTS.PROCESS, `data/${file.name}`);
    } catch (e) {
      toast(t('upload_page.messages.upload_failed'), { type: toast.TYPE.ERROR });

      setProcessing(false);
    }

    return resetForm();
  };

  const renderInvalidCSVURL = () => {
    if (!isEmpty(invalidCSVURL)) {
      return (
        <Card className="mt-3">
          <Card.Body>
            <p className="mb-0">
              {t('upload_page.invalid_csv.text')}
              {' '}
              <a
                href={invalidCSVURL}
              >
                {t('upload_page.invalid_csv.link')}
              </a>
              .
            </p>
          </Card.Body>
        </Card>
      );
    }
    return <></>;
  };

  const renderServerError = () => {
    if (serverError) {
      return (
        <Card.Body>
          <Alert variant="danger" className="mb-0">{serverError}</Alert>
        </Card.Body>
      );
    }
    return <></>;
  };

  const renderSpinner = () => {
    if (isProcessing) {
      return (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('upload_page.spinner.text')}</span>
          </Spinner>
        </div>
      );
    }
    return <></>;
  };

  const renderErrorSection = () => {
    if (isEmpty(errorMessages)) {
      return <></>;
    }
    return (
      <div className="mt-3 mb-3">
        <ErrorTableComponent data={errorMessages} />
      </div>
    );
  };

  const renderForm = () => {
    if (wsConnectionError) {
      return (
        <Alert
          variant="danger"
          className="mb-0"
        >
          {t('upload_page.messages.ws_connection_error')}
        </Alert>
      );
    }
    return (
      <div className="mt-5">
        <h4 className="mb-3">{t('upload_page.form.title')}</h4>
        <UploadFormComponent onSubmit={onSubmit} isProcessing={isProcessing} />
      </div>
    );
  };

  return (
    <Container>
      <Col lg="12">
        <div className="mt-4">
          <h2>{t('upload_page.header.message')}</h2>
          <p className="mt-3 fs-6" data-testid="upload-subtitle">
            {t('upload_page.header.subtitle')}
            {' '}
            <a
              href={FORMAT_INFORMATION_LINK}
              rel="noreferrer"
              target="_blank"
            >
              {t('upload_page.header.here')}
            </a>
            .
          </p>
        </div>
        {renderForm()}
        {renderSpinner()}
        {renderInvalidCSVURL()}
        {renderErrorSection()}
        {renderServerError()}
      </Col>
    </Container>
  );
};

export { UploadPage };
