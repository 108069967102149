import './services/amplify';
import './translation/i18n';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';

import { AppRoutes } from './routes';
import { AuthProvider } from './services/auth_provider';

const RootApp = () => (
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
    <ToastContainer
      autoClose={8000}
      position={toast.POSITION.TOP_RIGHT}
      closeOnClick
      pauseOnHover
      transition={Slide}
    />
  </React.StrictMode>
);

ReactDOM.render(
  <RootApp />,
  document.getElementById('root'),
);
