import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { LoginPage } from './pages/LoginPage/LoginPage';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { UploadPage } from './pages/UploadPage/UploadPage';
import { ProtectedRoute } from './services/auth_provider';

const AppRoutes = () => (
  <Routes>
    <Route exact path="/" element={<ProtectedRoute />}>
      <Route path="/" element={<UploadPage />} />
    </Route>
    <Route path="/auth/login" exact element={<LoginPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export { AppRoutes };
