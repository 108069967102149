import classNames from 'classnames';
import { Field, Formik } from 'formik';
import { get, split } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import {
  Button, Card, Form, InputGroup,
} from 'react-bootstrap';
import { Upload } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const UploadFormComponent = ({ onSubmit, isProcessing }) => {
  const { t } = useTranslation();
  const fileRef = useRef();

  const UploadFormSchema = useMemo(() => Yup.object().shape({
    uploadDestination: Yup.string().required(t('upload_form_component.validation.upload_destination.required')),
    file: Yup.mixed().required(t('upload_form_component.validation.file.required'))
      .test('fileFormat', t('upload_form_component.validation.file.format'), (value) => {
        const splittedName = split(get(value, 'name'), '.');
        return splittedName.length > 1 && splittedName.pop() === 'csv';
      }),
  }), [t]);

  const uploadFile = (form, { resetForm }) => {
    onSubmit(form, resetForm);
  };

  const onFormReset = () => {
    fileRef.current.value = '';
  };

  const renderForm = () => (
    <>
      <Formik
        initialValues={{
          uploadDestination: 'am-interface',
          file: undefined,
        }}
        validationSchema={UploadFormSchema}
        onReset={onFormReset}
        onSubmit={uploadFile}
      >
        {({
          handleSubmit,
          isValid,
          dirty,
          handleChange,
          setFieldValue,
          errors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Card
                className={classNames('pt-1 pb-1', { 'is-invalid': !!errors.uploadDestination })}
              >
                <Card.Body>
                  <div className="d-grid gap-2">
                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="uploadDestination"
                        onChange={handleChange}
                        value="am-interface"
                        id="am-interface"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="am-interface"
                      >
                        {t('upload_form_component.labels.am_interface')}
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        disabled
                        name="uploadDestination"
                        onChange={handleChange}
                        value="portal"
                        id="portal"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="portal"
                      >
                        {t('upload_form_component.labels.portal')}
                      </label>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Form.Control.Feedback type="invalid">
                {errors.uploadDestination}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <InputGroup hasValidation={!!errors.file}>
                <Form.Control
                  type="file"
                  id="file"
                  ref={fileRef}
                  isInvalid={!!errors.file}
                  onChange={(e) => setFieldValue('file', e.target.files[0])}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button type="submit" variant="dark" disabled={!isValid || !dirty || isProcessing}>
              <Upload />
              {' '}
              {t('upload_form_component.buttons.upload')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );

  return renderForm();
};

UploadFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

export { UploadFormComponent };
