import { Auth } from 'aws-amplify';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext, useEffect, useState,
} from 'react';
import {
  Navigate, Outlet, useLocation, useNavigate,
} from 'react-router-dom';

const FIVE_MINUTES = 5 * 60;
const AuthContext = createContext();

const AuthHook = () => {
  const [isAuthenticated, setAuthenticated] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const signOut = () => {
    setAuthenticated(false);
  };

  useEffect(() => {
    axios.interceptors.request.use(async (config) => {
      try {
        const currentSession = await Auth.currentSession();
        const token = currentSession.getIdToken().getJwtToken();
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      } catch (err) {
        // ignore
      }
      return config;
    }, (err) => Promise.reject(err));

    axios.interceptors.response.use((response) => response, (err) => {
      if (err.status === 401) {
        signOut();
      }
      return Promise.reject(err);
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const currentSession = await Auth.currentSession();
        if (!currentSession.isValid()) {
          setAuthenticated(false);
        } else {
          setAuthenticated(true);
        }
      } catch (err) {
        setAuthenticated(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        if (location.pathname === '/auth/login') {
          navigate('/');
        }
      } else {
        await Auth.signOut();
        navigate('/auth/login');
      }
    })();
  }, []);

  const signIn = async () => {
    await Auth.federatedSignIn();
  };

  const refreshToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const expirationTime = currentSession.getIdToken().getExpiration();
      const currentTimeSeconds = Math.round(new Date() / 1000) + FIVE_MINUTES;
      if (expirationTime < currentTimeSeconds) {
        cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {});
      }
    } catch (err) {
      setAuthenticated(false);
    }
  };

  useEffect(() => {
    const cancel = setInterval(refreshToken, 20 * 1000);
    return () => clearInterval(cancel);
  }, []);

  return {
    isAuthenticated,
    signIn,
    signOut,
  };
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const auth = AuthHook();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ProtectedRoute = () => {
  const auth = useAuth();
  const locationHook = useLocation();

  if (!auth.isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/auth/login',
          state: { from: locationHook.pathname },
        }}
      />
    );
  }
  return <Outlet />;
};
