const engTranslations = {
  translations: {
    login_page: {
      login_button: 'Login with Google to Excalibur',
    },
    not_found_page: {
      message: 'The requested page does not exist',
    },
    error_table_component: {
      table: {
        header: {
          line: 'Line',
          error_message: 'Error Message',
        },
      },
    },
    upload_form_component: {
      labels: {
        am_interface: 'AM Interface',
        portal: 'Portal',
      },
      buttons: {
        upload: 'Upload',
      },
      validation: {
        upload_destination: {
          required: 'Upload destination is required',
        },
        file: {
          required: 'File is required',
          format: 'Unsupported file format',
        },
      },
    },
    upload_page: {
      header: {
        message: 'Welcome to the manual Recommendation / Action Item upload',
        subtitle: 'Please upload your source file as a comma (or semicolon) separated CSV. Ensure that you have added the mandatory information in the right format. For more information download the template with an example',
        here: 'HERE',
      },
      messages: {
        ws_connection_error: 'Can not connect to the websocket',
        upload_failed: 'Upload failed',
        upload_successful: 'Upload successful',
        authorization_failed: 'Websocket authorization failed',
      },
      invalid_csv: {
        text: 'You can download CSV with invalid action items from following',
        link: 'link',
      },
      spinner: {
        text: 'Processing...',
      },
      form: {
        title: 'Upload Destination',
      },
    },
  },
};

export default engTranslations;
