import React, { useEffect } from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../services/auth_provider';

const LoginPage = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/');
    }
  }, []);

  const handleLogin = () => {
    auth.signIn();
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col lg="8">
          <Button onClick={handleLogin} variant="dark">{t('login_page.login_button')}</Button>
        </Col>
      </Row>
    </Container>
  );
};

export { LoginPage };
